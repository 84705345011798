import {
    NomenclatureTypeResource,
    AuditEventResource,
    AuditEventTypeResource,
} from 'speed-js-core';

import {
    CasesResource, OpenCasesResource, CaseCloseResource, CaseCurrentAlarmsResource, UserCasesResource,
    PatrolCasesResource, ActiveCasesResource, ProviderCasesResource, CaseCompleteByProviderResource,
    CaseManualResource, CaseCurrentSignalsResource,
} from './cases';
import { CommentWithAttachmentResource } from './comments';
import {
    ServiceTypeResource, ServiceProviderResource, VehicleResource, ImportResource, EmployeeRegisterResource,
    ServiceResource, ServiceAccessResource, ServiceRequestResource, ServiceRequestAccessResource,
    ServiceAccessByUserResource, ServiceAccessRequestsForReviewerResource, ActivateServiceResource,
    ServiceAccessCodeResource, ServiceNotificationsResource, MapPropertiesResource, ServiceDeactivateResource,
    AssetTypeResource, ServiceAssetResource, ServiceResellerGenericResource, ResellerResource,
    ServiceResellerNestedResource, EquipmentTypeResource, ServiceClientAuditEventsResource, ServiceZoneResource,
    ServiceTransferResource,
} from './services';
import {
    PatrolResource, PatrolGeoResource,
} from './patrols';
import { ParkingResource, ParkingMiddlewareResource } from './parking';
import ClientResource from './clients/ClientResource';
import EntityResource from './entities/EntityResource';
import OrganisationResource from './entities/OrganisationResource';
import EmployeeResource from './entities/EmployeeResource';
import {
    SiteResource, SiteGeoResource, SiteEquipmentAccessResource,
} from './sites';
import {
    UserResource, GroupResource, UserUpdateResource, UserContextResource,
} from './auth';
import {
    DeviceResource, DeviceRegisterResource, DevicePasswordResource, DeviceUnlockResource,
    DeviceUnregisterResource,
} from './devices';
import { AttachmentResource } from './attachments';
import { ContactDetailResource } from './contacts';
import { PastActivationsResource, PastSignalsResource } from './activations';
import {
    NotificationTypeResource, NotificationChannelResource, NotificationScopeResource,
    NotificationResource, GCMDeviceResource, APNSDeviceResource,
    NotificationPushDeviceResource,
} from './notifications';
import {
    PrivacyPolicyResource, TermsOfServiceResource,
} from './web';
import {
    TaskResource, TaskTemplateResource,
} from './tasks';

import {
    PricingPlanResource, BillingPeriodResource, PriceListResource, PriceListItemResource, BillingPolicyResource,
    BillResource, CommissionResource, BillPriceListItemResource, RunBillTaskResource,
} from './billing';

import { CameraResource } from './cameras';
import DispatchedToProviderCasesResource from './cases/DispatchedToProviderCasesResource';
import EBSDevice from './ebs/EBSDevice';


export default {
    attachments: AttachmentResource,

    auditEvents: AuditEventResource,
    auditEventTypes: AuditEventTypeResource,

    billPriceListItemResource: BillPriceListItemResource,

    cameras: CameraResource,

    cases: CasesResource,
    caseManual: CaseManualResource,
    closeCase: CaseCloseResource,
    completeCaseByProvider: CaseCompleteByProviderResource,

    clients: ClientResource,

    contacts: ContactDetailResource,

    currentAlarms: CaseCurrentAlarmsResource,
    currentSignals: CaseCurrentSignalsResource,

    comments: CommentWithAttachmentResource,

    devices: DeviceResource,
    deviceRegister: DeviceRegisterResource,
    deviceUnregister: DeviceUnregisterResource,
    devicePassword: DevicePasswordResource,
    deviceUnlock: DeviceUnlockResource,
    dispatchedToProviderCases: DispatchedToProviderCasesResource,

    ebsDevices: EBSDevice,

    entities: EntityResource,
    equipmentTypes: EquipmentTypeResource,

    employeeRegister: EmployeeRegisterResource,
    employees: EmployeeResource,

    groups: GroupResource,

    nomenclatureTypes: NomenclatureTypeResource,

    openCases: OpenCasesResource,

    activeCases: ActiveCasesResource,

    userCases: UserCasesResource,

    patrolCases: PatrolCasesResource,

    providerCases: ProviderCasesResource,

    organisations: OrganisationResource,

    patrols: PatrolResource,
    'patrols.geo': PatrolGeoResource,

    pastActivations: PastActivationsResource,
    pastSignals: PastSignalsResource,
    parking: ParkingResource,
    parkingMiddleware: ParkingMiddlewareResource,

    services: ServiceResource,
    serviceAccessCode: ServiceAccessCodeResource,
    serviceZones: ServiceZoneResource,
    serviceAccess: ServiceAccessResource,
    serviceAccessByUser: ServiceAccessByUserResource,
    serviceRequest: ServiceRequestResource,
    serviceRequestAccess: ServiceRequestAccessResource,
    serviceRequestsForReviewer: ServiceAccessRequestsForReviewerResource,
    activateService: ActivateServiceResource,
    serviceProviders: ServiceProviderResource,
    serviceTypes: ServiceTypeResource,
    serviceImport: ImportResource,
    serviceNotifications: ServiceNotificationsResource,
    serviceClientAuditEvents: ServiceClientAuditEventsResource,
    serviceDeactivate: ServiceDeactivateResource,
    assetType: AssetTypeResource,
    reseller: ResellerResource,
    serviceAsset: ServiceAssetResource,
    serviceTransfer: ServiceTransferResource,
    serviceResellerNested: ServiceResellerNestedResource,
    serviceResellerGeneric: ServiceResellerGenericResource,

    vehicles: VehicleResource,

    users: UserResource,
    usersUpdate: UserUpdateResource,
    userContexts: UserContextResource,

    sites: SiteResource,
    'sites.geo': SiteGeoResource,
    equipmentAccess: SiteEquipmentAccessResource,

    notifications: NotificationResource,
    notificationTypes: NotificationTypeResource,
    notificationChannels: NotificationChannelResource,
    notificationScopes: NotificationScopeResource,
    notificationPushDevices: NotificationPushDeviceResource,
    gcmDevices: GCMDeviceResource,
    apnsDevices: APNSDeviceResource,

    privacyPolicy: PrivacyPolicyResource,
    termsOfService: TermsOfServiceResource,

    mapProps: MapPropertiesResource,

    tasks: TaskResource,
    taskTemplates: TaskTemplateResource,

    bill: BillResource,
    commission: CommissionResource,
    pricingPlan: PricingPlanResource,
    billingPeriod: BillingPeriodResource,
    billingPolicy: BillingPolicyResource,
    priceList: PriceListResource,
    priceListItem: PriceListItemResource,
    billTask: RunBillTaskResource,
};
